export const PROJECT_NAME = process.env.REACT_APP_PROJECT_NAME || 'Whöse Order';
export const BASE_URL =
  process.env.REACT_APP_BASE_URL || 'http://api.whoseorder.test';
export const WEBSITE_URL =
  process.env.REACT_APP_WEBSITE_URL || 'http://localhost';
export const api_url = BASE_URL + '/api/v1/';
export const api_url_admin = BASE_URL + '/api/v1/dashboard/admin/';
export const api_url_admin_dashboard = BASE_URL + '/api/v1/dashboard/';
export const IMG_URL = '';
export const MAP_API_KEY = 'AIzaSyBbfnvObzwcs6OLXOmSaG9CtIhwjmBwLTQ';
export const export_url = BASE_URL + '/storage/';
export const example = BASE_URL + '/';

export const VAPID_KEY =
  'BHFv5NaxfCmJ9s2VPGdSG9TZ5gdux45UOpJW9fUDoFeqAsXv8XFZmzMI7vp84B6QAKiCD1eMy8E4M9f1RRPfRR0';

export const LAT = 47.4143302506288;
export const LNG = 8.532059477976883;

export const API_KEY =
  process.env.REACT_APP_FIREBASE_API_KEY ||
  'AIzaSyDgRw0QtaSJ3mPp92gTUFtprw5MCMrtnPk';
export const AUTH_DOMAIN =
  process.env.REACT_APP_FIREBASE_AUTH_DOMAIN ||
  'whoseorder-dev.firebaseapp.com';
export const PROJECT_ID =
  process.env.REACT_APP_FIREBASE_PROJECT_ID || 'whoseorder-dev';
export const STORAGE_BUCKET =
  process.env.REACT_APP_FIREBASE_STORAGE_BUCKET || 'whoseorder-dev.appspot.com';
export const MESSAGING_SENDER_ID =
  process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID || '591772522204';
export const APP_ID =
  process.env.REACT_APP_FIREBASE_APP_ID ||
  '1:591772522204:web:a1a808d7f42d0bc52b4619';
export const MEASUREMENT_ID =
  process.env.REACT_APP_FIREBASE_MEASUREMENT_ID || 'G-HLDLY84B8V';

export const RECAPTCHASITEKEY =
  process.env.REACT_APP_RECAPTCHASITEKEY ||
  '6Lf38QwqAAAAAFDWZtnjmt6c0DSF4yi8R8bwF4zO';

export const DEMO_SELLER = 334; // seller_id
export const DEMO_SELLER_UUID = '3566bdf6-3a09-4488-8269-70a19f871bd0'; // seller_id
export const DEMO_SHOP = 599; // seller_id
export const DEMO_DELIVERYMAN = 375; // deliveryman_id
export const DEMO_MANEGER = 114; // deliveryman_id
export const DEMO_MODERATOR = 297; // deliveryman_id
export const DEMO_ADMIN = 107; // deliveryman_id

export const SUPPORTED_FORMATS = [
  'image/jpg',
  'image/jpeg',
  'image/png',
  'image/svg+xml',
  'image/svg',
];
